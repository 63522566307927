
import themeMixin from '~/mixins/theme'
import defaultHTMLSerializer from '~/app/prismic/html-serializer'

export default {
  name: 'CardWithTabbedTextListNewClientCases',
  mixins: [themeMixin],
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      selectedItem: 0,
    }
  },
  computed: {
    tabTitleItems() {
      if (!this.slice?.items) {
        return []
      }
      const allItems = this.slice.items

      const arr = allItems.reduce((acc, item) => {
        return [...acc, item.tabTitle]
      }, [])

      return arr
    },
    themeContrastColor() {
      return this.getContrastColor(this.theme)
    },
    themeComputedClass() {
      return this.getThemeClass(this.theme)
    },
    cardButtonTheme() {
      return this.slice.primary.cardButtonTheme || this.themeComputedClass
    },
    theme() {
      return this.slice?.primary?.theme || 'ocean-blue'
    },
    cardButton() {
      const {
        cardButtonText: buttonText,
        cardButtonLink: buttonLink,
        cardCtaLabel: ctaLabel,
        enableTracking,
        cardCtaAnchorId: anchorId,
      } = this.slice.primary

      if (buttonText && buttonLink) {
        return {
          buttonText,
          buttonLink,
          ctaLabel,
          enableTracking,
          anchorId,
        }
      }

      return null
    },
  },
  methods: {
    htmlSerializer(type, element, content, children) {
      const customHTMLClassConfig = {
        heading3: '!mb-12 font-mulish',
        heading5: 'mb-4 !text-size-h4-desktop font-mulish font-size',
        paragraph: 'mb-12',
      }

      return defaultHTMLSerializer(
        type,
        element,
        content,
        children,
        customHTMLClassConfig
      )
    },
    htmlSerializerMobile(type, element, content, children) {
      const customHTMLClassConfig = {
        heading3: '!mb-8 text-size-h3-mobile font-mulish',
        heading5: 'mb-4 text-size-h4-mobile font-mulish',
      }

      return defaultHTMLSerializer(
        type,
        element,
        content,
        children,
        customHTMLClassConfig
      )
    },
    selectedItemChanged(index) {
      this.selectedItem = index
    },
  },
}
