import { render, staticRenderFns } from "./index.vue?vue&type=template&id=01c9967a&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PrismicTitleDescriptionAndLink: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Prismic/TitleDescriptionAndLink/index.vue').default,PrismicButtonLinkComponent: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Prismic/ButtonLinkComponent/index.vue').default,ElementsIcon: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/Icon/index.vue').default,ComponentsCardsCollapsible: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Cards/Collapsible.vue').default,LayoutSpacingHorizontal: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Layout/Spacing/Horizontal.vue').default,LayoutSpacingVerticalHeroBody: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Layout/Spacing/VerticalHeroBody.vue').default})
