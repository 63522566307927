
import themeMixin from '~/mixins/theme'

export default {
  mixins: [themeMixin],
  props: {
    items: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    theme: {
      type: String,
      required: false,
      default: null,
    },
    selectedItem: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  methods: {
    clicked(index) {
      this.$emit('selectedItemChanged', index)
    },
    themeContrastColor() {
      return this.getContrastColor(this.theme)
    },
  },
}
