
import themeMixin from '~/mixins/theme'
import defaultHTMLSerializer from '~/app/prismic/html-serializer'

export default {
  name: 'CardWithTabbedTextListClientCases',
  mixins: [themeMixin],
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      selectedItem: 0,
    }
  },
  computed: {
    bulletedText() {
      return (
        this.slice.primary.bulletedText?.filter(
          (textElement) => textElement?.text?.trim().length
        ) || []
      )
    },
    iconContrastColorToTheme() {
      return this.getIconContrastColor()
    },
    iconContrastColor() {
      return this.getIconContrastColor(this.theme)
    },
    themeContrastColor() {
      return this.getContrastColor(this.theme)
    },
    cardButtonTheme() {
      return this.slice.primary.cardButtonTheme || this.themeComputedClass
    },
    themeComputedClass() {
      return this.getThemeClass(this.theme)
    },
    theme() {
      return this.slice?.primary?.theme || 'ocean-blue'
    },
    cardButton() {
      const {
        cardButtonText: buttonText,
        cardButtonLink: buttonLink,
        cardCtaLabel: ctaLabel,
        enableTracking,
        cardCtaAnchorId: anchorId,
        cardButtonTheme: theme,
      } = this.slice.primary

      if (buttonText && buttonLink) {
        return {
          buttonText,
          buttonLink,
          ctaLabel,
          enableTracking,
          anchorId,
          theme,
        }
      }

      return null
    },
  },
  methods: {
    htmlSerializer(type, element, content, children) {
      const customHTMLClassConfig = {
        heading5: 'mb-4',
        paragraph: 'mb-4',
        image: 'mb-20 w-full',
      }

      return defaultHTMLSerializer(
        type,
        element,
        content,
        children,
        customHTMLClassConfig
      )
    },
    htmlSerializerMobile(type, element, content, children) {
      const customHTMLClassConfig = {
        heading5: 'mb-4',
        image: 'mb-8 max-w-18 relative left-2/4 -translate-x-2/4',
      }

      return defaultHTMLSerializer(
        type,
        element,
        content,
        children,
        customHTMLClassConfig
      )
    },
    clicked(index) {
      this.selectedItem = index
    },
  },
}
