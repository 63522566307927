
import themeMixin from '~/mixins/theme'
import defaultHTMLSerializer from '~/app/prismic/html-serializer'

export default {
  name: 'CardWithTabbedTextList',
  mixins: [themeMixin],
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      selectedItem: 0,
    }
  },
  computed: {
    paragraphItems() {
      return this.hashedItemsOfType('paragraph')
    },
    imageItems() {
      return this.hashedItemsOfType('image')
    },
    themeComputedClass() {
      return this.getThemeClass(this.theme)
    },
    theme() {
      return this.slice?.primary?.theme || 'sky-blue'
    },
  },
  methods: {
    htmlSerializer(type, element, content, children) {
      const customHTMLClassConfig = {
        paragraph: 'mt-6 font-roboto',
      }

      return defaultHTMLSerializer(
        type,
        element,
        content,
        children,
        customHTMLClassConfig
      )
    },
    hashedItemsOfType(type) {
      const hash = {}
      this.slice.items.forEach((item, index) => {
        hash[index] = [
          ...item.description.filter((content) => content.type === type),
        ]
      })

      return hash
    },
    clicked(index) {
      this.selectedItem = index
    },
  },
}
