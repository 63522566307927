
import DefaultComponent from './default-slice'
import ClientCases from './clientCases'
import NewClientCases from './newClientCases'

export default {
  name: 'CardsWithImages',
  components: {
    DefaultComponent,
    ClientCases,
    NewClientCases,
  },
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  computed: {
    variation() {
      return this.slice.variation
    },
    component() {
      const mappings = {
        'default-slice': 'DefaultComponent',
        clientCases: 'ClientCases',
        newClientCases: 'NewClientCases',
      }

      return mappings[this.variation] || 'DefaultComponent'
    },
  },
}
