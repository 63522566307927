
import themeMixin from '~/mixins/theme'

export default {
  mixins: [themeMixin],
  props: {
    expand: {
      type: Boolean,
      required: false,
      default: false,
    },
    theme: {
      type: String,
      required: false,
      default: 'dark-navy',
    },
  },
  data() {
    return {
      isExpanded: this.expand,
    }
  },
  computed: {
    themeComputedClass() {
      return this.getThemeClass(this.theme)
    },
  },
  methods: {
    toggleExpandedState($event, index) {
      $event.preventDefault()
      $event.stopPropagation()
      this.isExpanded = !this.isExpanded
    },
  },
}
